$color-blossom: #1982d1;
$color-fade: $color-blossom;

$color-bg: #f9f9f9;
$color-bg-alt: #f1f1f1;

$color-text: #2f2f2f;
$font-size-base: 1.8rem;

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$font-family-heading: $font-family-base;

$beige: #A87E66;
$beige-dark: #866451;
$subtle-grey: #F9F9F9;
$mid-grey: #CCC;
